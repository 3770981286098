<template>
  <div class="online">
    <div class="container">
      <!-- 底图 -->
      <img :src="info.images"/>

      <div class="title">
        <div>
          <h4>{{ info.bookname }}</h4>
          <h6>{{ info.teacher_name }}</h6>
        </div>
      </div>
      <div class="text">
        <div class="gold-num fl" v-show="parseInt(info.price) > 0">￥{{ info.price }}</div>
        <div class="fr">
          <span class="gold-num">{{ info.buynum }}</span>人已购
        </div>
      </div>
      
      <div class="verify" v-show="!isShowAddress">
				<div class="code">
					<i class="jym-iconfontshouji"></i>
					<input type="text" v-model="phone" placeholder="输入手机号码"/>
				</div>
				<div class="code">
					<i class="jym-anquan"></i>
					<input type="text" v-model="phoneCode" placeholder="填写验证码"/>
					<button :class="isWait ? 'wait':''" @click="verifyCode">{{isWait ? `${waitTime}秒` : '获取验证码'}}</button>
				</div>
				<div class="submit" @click="submitCode">提交</div>
			</div>

      <div class="address-info" v-show="isShowAddress">
        <div v-if="addressList.length">
          <div class="text" style="border:1px soild #ccc">
            <div class="fl">用户名：{{userInfo.realname}}</div>
            <div class="fr">手机号：{{userInfo.phone}}</div>
          </div>
          <div class="address" v-for="(item, index) in addressList" :key="index">
            <div class="info">
              <p>{{ item.alias }}</p>
              <p>{{ item.starttime.substring(0, item.starttime.length - 3) }}</p>
            </div>
            <div class="apply">
              <button
                @click="apply(item.id)"
                :class="item.num > 0 ? 'abled' : 'disabled'"
                :disabled="item.num > 0 ? false : true"
              >选择</button>
              <span>{{ item.num > 0 ? `剩余${item.num}人`: '截止报名' }}</span>
            </div>
          </div>
        </div>
        <div v-else class="desc">
          <div class="emptyData">
            <img src="@assets/images/emptyData.png" />
            <p>暂无听课地点</p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { Toast } from 'mint-ui';
import { mapGetters } from "vuex";
import { getCodeApi, registerVerify } from "../../api/user";
import { phoneLesson, orderVerify, createOrder } from "@api/public";

export default {
  name: "offline-detail",
  components: {
  },
  data() {
    return {
      id: "",
      sid: "",
      certId: "",
      positionPrice: "",
      keyCode: "",
      waitTime: '60',
      phone: "",
      phoneCode: "",
      info: {},
      userInfo: {},
      addressList: [],
      isWait: false,
      isShowAddress: false
    };
  },
  computed: mapGetters(["isLogin"]),
  created() {
    this.id = this.$route.params.id;
    this.getCode();
    this.getList();
  },
  methods: {
    // 获取列表数据
    getList() {
      let that = this;
      phoneLesson(this.id).then((res) => {
        that.$set(that, "info", res.data);        
      });
    },

    // 获取key
    getCode() {
      getCodeApi()
        .then(res => {
          this.keyCode = res.data.key;
        })
        .catch(res => {
          this.$dialog.error(res.msg);
        });
    },

    // 获取验证码
    verifyCode() {
      let that = this
      let waitTimer = ''
      if (!that.phone || !(/^1[3456789]\d{9}$/.test(that.phone))) {
        Toast({message: '请输入正确的验证码', iconClass: 'jym-cuo'});
      } else if(that.isWait) {
        Toast({message: '请等待倒计时结束'});
      } else {					
        let params = {
          code: '',
          key: that.keyCode,
          phone: that.phone,
          type: 'verify',
          class: 1,
          link_id: that.id
        }
        
        registerVerify(params).then(res => {
          Toast({message:res.msg, iconClass: 'jym-dui'});
          that.isWait = true
          that.waitTimer = setInterval(()=> {
            that.waitTime = --that.waitTime
          }, 1000)
          setTimeout(()=> {
            that.waitTime = '60'
            that.isWait = false
            clearInterval(waitTimer)
          }, 60000)
        }).catch(err => {
          Toast({message: err.msg, iconClass: 'jym-cuo'});
        })
      }      
    },
			
    // 提交验证码
    submitCode() {
      let that = this
      if (!that.phone || !(/^1[3456789]\d{9}$/.test(that.phone))) {
        Toast({message: '请输入正确的手机号', iconClass: 'jym-cuo'});
      } else if (!that.phoneCode || !/^\d{6}$/.test(that.phoneCode)) {
        Toast({message: '请输入正确的验证码', iconClass: 'jym-cuo'});
      } else {					
        let params = {
          code: that.phoneCode,
          phone: that.phone,
          link_id: that.id
        }
        
        orderVerify(params).then(res => {
          Toast({message: '验证成功', iconClass: 'jym-dui'});
          that.$set(that, 'certId', res.data.certId)
          that.$set(that, 'userInfo', res.data.info)
          that.$set(that, 'addressList', res.data.siteList)
          that.isShowAddress = true
        }).catch(err => {
          Toast({message: err.msg, iconClass: 'jym-cuo'});
        })
      }
    },

    // 申请地址
    apply(sid) {
      let that = this
      let params = {
        id: that.id,
        sid: sid,
        cert_ids: String(that.certId).split(','),
        verify_id: that.userInfo.id
      }
      createOrder(params).then(res => {
         that.$router.push({
          path: `/lesson/pay`,
          query: { order_id: res.data.order_id },
        });
      }).catch(err => {
        let status = err.data.status;

        Toast({message: err.msg, iconClass: 'jym-cuo'});
        setTimeout(() => {
          if (status === 101) {
            // 课程不存在
            that.$router.push({ path: "/" });
          } else if (status === 201 || status === 202) {
            // 免费课程不需要购买 || 您已购买该课程
            that.$router.push({ path: "/lesson/offline/" + that.id });
          } else if (status === 301 || status === 403) {
            // 您还有该课程未付款订单
            that.$router.push({ path: "/user/MyLesson" });
          } else {
            return true
          }
        }, 1000)
      })
    },

  }

};
</script>

<style lang="scss" scoped>
.online {
  padding-bottom: 1rem;
  .container {
    background: white;
    position: relative;
    img {
      width: 100%;
      height: auto;
      display: block;
    }
    .title {
      display: flex;
      justify-content: space-between;
      padding: 0.2rem 0.3rem .1rem;
      h4 {
        font-size: 0.28rem;
      }
      h6 {
        font-size: 0.26rem;
        font-weight: 500;
        line-height: 0.6rem;
        color: #b0b0b0;
      }
    }
    .text {
      padding: 0 0.3rem;
      overflow: hidden;
      i {
        font-size: 0.28rem;
      }
    }
    .address-info {
      margin-top: .5rem;
      .address {
        width: 6.9rem;
        margin: 0 0.3rem;
        padding: 0.3rem 0;
        border-bottom: 0.02rem dashed #ddd;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        .info {
          width: 5rem;
          p {
            margin-bottom: 0.06rem;
            line-height: 0.36rem;
            font-size: 0.26rem;
          }
        }
        .apply {
          width: 1.5rem;
          font-size: 0.24rem;
          text-align: center;
          button {
            display: block;
            margin: 0 auto 0.06rem;
            padding: 0.1rem 0.24rem;
            border-radius: 0.04rem;
            letter-spacing: 0.04rem;
          }
          .abled {
            color: white;
            background: #6b7bb0;
          }
          .disabled {
            color: #666;
            background: #ddd;
          }
        }
      }
    }
  }
  .verify {
		background: white;
    padding: .3rem 0 .5rem;
    .code {
      width: 90%;
      margin: 0 auto;
      position: relative;
      padding-top: .2rem;
      input {
        width: 100%;
        height: 0.8rem;
        line-height: 0.8rem;
        border-bottom: 1px solid #ededed;
        font-size: .3rem;
        padding: 0 1.5rem 0 1rem;
      }
      i {
        font-size: .36rem;
        position: absolute;
        top: .38rem;
        left: .2rem;
        color: #f11b09;
      }
      button {
        position: absolute;
        top: .34rem;
        right: 0;
        height: .46rem;
        line-height: .46rem;
        width: 1.5rem;
        font-size: .26rem;
        border-radius: .3rem;
        color: white;
        text-align: center;
        background: #f35446;
      }
      .wait {
        background: #ccc;
      }
    }
    .submit {
      width: 90%;
      margin: 0 5%;
      font-size: .3rem;
      color: #fff;
      font-weight: 700;
      height: .86rem;
      border-radius: .43rem;
      background: linear-gradient(to right,#f35447 0,#ff8e3c 100%);
      text-align: center;
      line-height: .86rem;
      margin-top: .47rem;
    }
	}
}
</style>
